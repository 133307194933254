import './App.css';
import React,{ useState, useEffect } from 'react';
import { withAuthenticator }                      from '@aws-amplify/ui-react';
import { API, Auth }                              from 'aws-amplify';

function App() {
  const [key,setKey] = useState('');
  const [responses,setResponses] = useState([]);
  const [prompt,setPrompt] = useState('');
  const [loading,setLoading] = useState(false);

  function generateResponse() {
    if (loading) { setPrompt('Generating.. please wait for the response to load before making another request..'); return; }
    setLoading(true);
    try {
        let promptInput = prompt;
        setPrompt('Generating...');
        fetch("https://api.openai.com/v1/completions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+key,
            },
            body: JSON.stringify({
                model: "text-davinci-003",
                prompt: promptInput,
                max_tokens: 512,
                temperature: 0.5,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            }),
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            setPrompt('');
            addResponse(data.choices[0].text.trim());
        });
    } catch(error) {
      setPrompt(error);
    }

  }

  function addResponse(response) {
    setResponses([response,...responses]);
  }

  useEffect(()=>{
    API.get('lizbotapi', '/token').then(response => {
      setKey(response);
    }).catch(error => {
      setPrompt(error);
    });
  },[])

  return (
    <div className="App">
      <h1>"More Than AI Its Liz Martin Power"</h1>
      <div className="Generator">
        <div className="Title">
          <span>Radio Script Generator</span>
        </div>
        <div className="Input">
          <textarea value={prompt} onChange={({ target: { value } })=>{setPrompt(value)}} placeholder="Enter your input"></textarea>
          <button
            onClick={()=>{
              generateResponse();
            }}
          ><strong>GENERATE</strong> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg></button>
        </div>
        <div className="Output">
            {
              responses.map((response,index)=>{
                return <span>Response {responses.length-index}:<br/>{response}</span>
              })
            }
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(App);
